<template>
  <div v-if="certificates">
    <h1>Overview all deployments</h1>
    <grid>
      <grid-col size="10">
        <card title="" v-if="certificates.length">
          <div v-for="certificate in certificates" :key="certificate._id">
            <div v-if="certificate.deployments.length">
              <h4>{{ certificate.name }}</h4>
              <div
                v-for="deploy in certificate.deployments"
                :key="[certificate._id, deploy.device].join(':')"
              >
                <div>
                  <span v-if="deploy.started && deploy.finished">
                    <i v-if="!deploy.error" class="uil uil-check-circle success"></i>
                    <i v-else class="uil uil-exclamation-triangle error"></i>
                  </span>
                  <span v-else>
                    <i class="uil uil-question-circle"></i>
                  </span>
                  <b>{{ deploy.name }}</b>
                </div>
                <div v-if="deploy.started || deploy.finished" style="padding-left: 20px">
                  <br />
                  <div v-if="deploy.started">Started: {{ datefmt(deploy.started) }}</div>
                  <div v-if="deploy.finished">Finished: {{ datefmt(deploy.finished) }}</div>
                  <div v-if="deploy.started && deploy.finished">
                    Elapsed: {{ elapsed(deploy.started, deploy.finished) }}
                  </div>
                  <div v-if="deploy.error">
                    Error: <b class="error">{{ deploy.error }}</b>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </card>
      </grid-col>
    </grid>
  </div>
</template>

<script>
import Utils from '@/utils';
import BaseComponent from '../Base.vue';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';

export default {
  name: 'CreateEditcertificate',
  extends: BaseComponent,
  components: {
    Card,
    Grid,
    GridCol,
  },
  props: ['certificates'],
  watch: {},
  data() {
    return {
      ajaxCompleted: false,
    };
  },
  methods: {
    onCancel() {
      this.$router.push('/certificate');
    },
    datefmt(str) {
      return this.$date(str).format('LLL');
    },
    elapsed(st, fi) {
      return Utils.durationShort(this.$date(fi).subtract(this.$date(st)), {
        units: ['m', 's'],
        round: true,
      });
    },
  },
  async mounted() {
    // this.getcerts();
  },
};
</script>
